import React, { Component } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import Dialog from '@material-ui/core/Dialog'
import { Text, Block } from 'components/atoms'

class Alert extends Component {
  render() {
    const { open, title, description } = this.props
    return (
      <Dialog open={open}>
        <Block.MiddleCenter
          flexDirection={'column'}
          minWidth={[270, 400, 400]}
          minHeight={150}
        >
          <Text.ThaiHeading mb={10} textAlign={'center'}>
            {title}
          </Text.ThaiHeading>
          <Text fontSize={20} px={24} textAlign={'center'} color={'grey'}>
            {description}
          </Text>
        </Block.MiddleCenter>
      </Dialog>
    )
  }
}

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
Alert.defaultProps = {
  title: 'กรุณารอสักครู่...',
  description: 'กำลังดำเนินการ',
}

export default Alert
