import React from 'react'
import clsx from 'clsx'
import { Block, Image, Text } from 'components/atoms'
import { AddressCard, RewardCard } from 'components/molecules'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { withStyles, makeStyles } from '@material-ui/core'
import StepConnector from '@material-ui/core/StepConnector'
import SettingsIcon from '@material-ui/icons/CardGiftcard'
import GroupAddIcon from '@material-ui/icons/AccessTime'
import VideoLabelIcon from '@material-ui/icons/LocalShipping'
import CheckIcon from '@material-ui/icons/Check'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 28,
  },
  active: {
    '& $line': {
      background: '#ef5b34',
    },
  },
  completed: {
    '& $line': {
      background: '#ef5b34',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    background: '#ef5b34',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: '#ef5b34',
  },
})

function StepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <CheckIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const RedeemDetail = props => {
  const [activeStep, setActiveStep] = React.useState(2)
  return (
    <Block
      p={16}
      display={'flex'}
      flexDirection={['column', 'row']}
      justifyContent={'space-between'}
    >
      <Text link onClick={() => props.goBack()}>
        {'< กลับ'}
      </Text>
      <Block>
        <Block mb={16}>
          <Text variant={'h3'} mb={16}>
            สถานะการจัดส่ง
          </Text>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            style={{ padding: 0 }}
          >
            {['Redeem', 'Waiting', 'Shipping', 'Received'].map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>
                  <Text variant={'caption'} fontWeight={'inherit'}>
                    {label}
                  </Text>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Block>
        <Block mb={16}>
          <Text mb={16} variant={'h3'}>
            ที่อยู่จัดส่ง
          </Text>
          <AddressCard
            address={{
              receiver: 'Work Place',
              address: '819 AIA Capital Center Ratchadapisek Rd.',
              district: 'Din Dang',
              subDistrict: 'Din Dang',
              province: 'Bangkok',
              postcode: '10400',
              phone: '022223232',
            }}
          />
        </Block>
      </Block>
      <Block>
        <Text mb={16} variant={'h3'}>
          ของรางวัล
        </Text>
        <RewardCard
          reward={{
            name: 'Reward 01',
            image:
              'https://firebasestorage.googleapis.com/v0/b/warrix-crm-staging.appspot.com/o/rewards%2Fwa-3326-de_1.jpg?alt=media&token=663a91f1-568d-4f2e-b19e-76346e6b2018',
            point: 25,
          }}
        />
      </Block>
    </Block>
  )
}

export default RedeemDetail
