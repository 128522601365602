import React, { useEffect } from 'react'
import get from 'lodash/get'
import { Block, Text, Button } from 'components/atoms'
import { CoverCard, TierProgress } from 'components/molecules'
import {
  RewardDetail,
  HistorySection,
  RewardSection,
} from 'components/organisms'

// REDUX
import { getAddress } from 'modules/auth/actions'
import { getReward } from 'modules/reward/actions'
import { connect } from 'react-redux'
import { selectAddressData } from 'modules/auth/selectors'
import { selectRewardData } from 'modules/reward/selectors'
import { bindActionCreators } from 'redux'

const MyCardSection = ({
  point,
  profile,
  getAddress,
  addresses,
  rewards,
  getReward,
  showMenu,
  gotoCollectSection,
}) => {
  const [activeSection, setActiveSection] = React.useState('Card')
  const [activeReward, setActiveReward] = React.useState(null)

  useEffect(() => {
    if (addresses.length === 0) {
      getAddress()
    }
    if (rewards.length === 0) {
      getReward()
    }
  }, [])

  useEffect(() => showMenu(activeSection === 'Card'), [activeSection])

  return (
    <Block>
      {activeSection === 'Card' && (
        <Block>
          <Block
            p={20}
            display={['block', 'block', 'flex']}
            justifyContent={'space-between'}
          >
            <Block width={['auto', 470]} margin={'auto'}>
              <CoverCard
                cover={get(
                  profile,
                  'member.image',
                  require('../../assets/starter-card.png')
                )}
                point={point}
              />
              <Block>
                <Text
                  link
                  onClick={() => setActiveSection('History')}
                  mt={15}
                  textAlign={'right'}
                >
                  ประวัติการสะสม/แลกแต้ม
                </Text>
              </Block>
            </Block>
            <Block width={['100%', '90%', '65%']} px={[0, 20]}>
              <Block
                display={['flex']}
                mt={20}
                justifyContent={'space-between'}
                alignItems={'baseline'}
              >
                <Text variant={'h3'}>Tier Progress</Text>
              </Block>
              <TierProgress
                point={get(profile, 'memberPoint')}
                currentMember={get(profile, 'member')}
                nextMember={get(profile, 'nextMember')}
              />
              <Block textAlign={['center', 'end']}>
                <Button.Contain
                  width={['100%', 200]}
                  onClick={() => gotoCollectSection()}
                >
                  สะสมแต้ม
                </Button.Contain>
              </Block>
            </Block>
          </Block>
          <Block
            p={15}
            my={['5px', 20]}
            mx={[0, 20]}
            background={'#f7f7f7'}
            width={'auto'}
          >
            <Text variant={'h4'}>แลกของรางวัล</Text>
          </Block>
          <RewardSection
            rewards={rewards}
            setReward={reward => {
              setActiveSection('RewardDetail')
              setActiveReward(reward)
            }}
          />
        </Block>
      )}
      {activeSection === 'History' && (
        <HistorySection backToCard={() => setActiveSection('Card')} />
      )}
      {activeSection === 'RewardDetail' && (
        <RewardDetail
          backToCard={() => {
            setActiveSection('Card')
            setActiveReward(null)
          }}
          reward={activeReward}
          userPoint={point}
        />
      )}
    </Block>
  )
}

const mapStateToProps = state => ({
  addresses: selectAddressData(state),
  rewards: selectRewardData(state),
})

export default connect(
  mapStateToProps,
  dispatch =>
    bindActionCreators(
      {
        getAddress,
        getReward,
      },
      dispatch
    )
)(MyCardSection)
