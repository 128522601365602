import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import CloudApi from 'api/cloudApi'
import InputLabel from '@material-ui/core/InputLabel'
import Close from '@material-ui/icons/Close'
import { Block, Text, Button, GatsbyImage, Image } from 'components/atoms'
import { TransactionModal } from 'components/organisms'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import findIndex from 'lodash/findIndex'
import uniq from 'lodash/uniq'
import CollectionGif from '../../../assets/tag.gif'

// REDUX
import { getProfile } from 'modules/auth/actions'
import { getTransactions } from 'modules/history/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const editIcon = 'collectCard.png'

const CollectSection = ({ getProfile, getTransactions }) => {
  const [loading, setLoading] = React.useState(false)
  const [redeemCodes, setRedeemCodes] = React.useState([null, null, null])
  const [result, setResult] = React.useState([])
  const [openTransactionModal, setOpenTransactionModal] = React.useState(false)
  const [errorCodes, setErrorCodes] = React.useState([])

  const handleSubmitCode = e => {
    e.preventDefault()
    setLoading(true)
    return CloudApi.collectPoint(redeemCodes.filter(code => code)).then(
      result => {
        setResult(result)
        setLoading(false)
        const errorCodes = result
          .map(code => {
            if (code.status === 'fail') return code.code
            return null
          })
          .filter(data => data)
        if (errorCodes.length > 0) {
          setErrorCodes(errorCodes)
          let updateRedeemCodes = [...redeemCodes]
          setRedeemCodes([])
          setRedeemCodes(
            updateRedeemCodes.filter(
              code =>
                findIndex(errorCodes, errorCode => errorCode === code) >= 0
            )
          )
        } else {
          setErrorCodes([])
          setRedeemCodes([])
          setRedeemCodes([null, null, null])
        }
        if (result.filter(data => data.status === 'success').length > 0) {
          getTransactions(true)
          setOpenTransactionModal(true)
          getProfile()
        }
      }
    )
  }

  const checkCodeIsSame = () => {
    return (
      redeemCodes.filter(code => code !== '' && code !== null).length !==
      uniq(redeemCodes.filter(code => code !== '' && code !== null)).length
    )
  }

  return (
    <Block p={[20, 0]} maxWidth={['auto', 500, 700]} m={'auto'}>
      <Text variant={'h1'}>สะสมแต้ม</Text>
      <Text my={15}>กรอกรหัสเพื่อสะสมแต้มจากใบรหัสที่ได้จากสินค้า</Text>
      <Block
        display={['block', 'flex']}
        mb={15}
        mt={20}
        justifyContent={'center'}
        alignItems={['center']}
      >
        <Image
          src={CollectionGif}
          width={[280, 350]}
          height={[280, 350]}
          mr={['none', 25]}
          m={['auto', 'none']}
        />
        <Block>
          <Text fontWeight={'bold'} mb={['5px', 15]}>
            วิธีการสะสม
          </Text>
          <Text color={'#363636'}>
            1. นำเหรียญ มาขูดแถบรหัสจากใบรหัสที่อยู่ในซองสินค้า
          </Text>
          <Text color={'#363636'}>
            2. นำรหัสมาเติมลงใน Website ที่ช่องรหัสด้านล่าง
          </Text>
        </Block>
      </Block>
      <form onSubmit={handleSubmitCode}>
        {redeemCodes.map((redeemCode, index) => (
          <Block
            position={'relative'}
            css={{
              '.MuiFormHelperText-root': {
                color: '#f2483f',
              },
            }}
          >
            <FormControl margin="dense" fullWidth>
              <InputLabel>โค้ดของคุณ</InputLabel>
              <Input
                type={'text'}
                value={redeemCode}
                placeholder={'00000000'}
                onChange={e => {
                  let updateRedeemCodes = [...redeemCodes]
                  updateRedeemCodes[index] = e.target.value
                  setRedeemCodes(updateRedeemCodes)
                }}
                error={
                  findIndex(
                    errorCodes,
                    errorCode => errorCode === redeemCode
                  ) >= 0
                }
              />
              {findIndex(errorCodes, errorCode => errorCode === redeemCode) >=
                0 && (
                <FormHelperText id="component-error-text">
                  ไม่สามารถใช้งานโค้ดนี้ได้
                </FormHelperText>
              )}
            </FormControl>
            <Block
              display={redeemCodes.length === 1 ? 'none' : 'block'}
              position={'absolute'}
              top={'30px'}
              right={0}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                let updateRedeemCodes = [...redeemCodes]
                setRedeemCodes(
                  updateRedeemCodes.filter(
                    (_data, codeIndex) => codeIndex !== index
                  )
                )
              }}
            >
              <Close style={{ fontSize: 18 }} />
            </Block>
          </Block>
        ))}
        <Block
          textAlign={'center'}
          onClick={() => {
            let updateRedeemCodes = [...redeemCodes]
            updateRedeemCodes.push(null)
            console.log('updateRedeemCodes', updateRedeemCodes)
            setRedeemCodes(updateRedeemCodes)
          }}
        >
          <AddCircleOutline
            style={{
              color: '#bababa',
              fontSize: 35,
              cursor: 'pointer',
              marginTop: 20,
            }}
          />
        </Block>
        {checkCodeIsSame() && (
          <Text mt={20} mb={20} color={'#f2483f'}>
            Please not use the same code
          </Text>
        )}
        <Button.Contain
          type="submit"
          color={'primary'}
          fullWidth
          mt={20}
          transform={'skewX(-20deg)'}
          loading={loading}
          disabled={
            redeemCodes.filter(redeemCode => redeemCode).length === 0 ||
            checkCodeIsSame()
          }
        >
          redeem code
        </Button.Contain>
      </form>
      {checkCodeIsSame()}
      <TransactionModal
        open={openTransactionModal}
        result={result}
        onClose={() => setOpenTransactionModal(false)}
      />
    </Block>
  )
}

export default connect(
  null,
  dispatch =>
    bindActionCreators(
      {
        getProfile,
        getTransactions,
      },
      dispatch
    )
)(CollectSection)
