import React from 'react'
import Text from './Text'

const EngHeading = props => {
  return (
    <Text letterSpacing={'0.5px'} fontSize={3} fontWeight={'bold'} {...props} />
  )
}

export default EngHeading
