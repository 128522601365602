import React from 'react'
import { Block, Text, Image } from 'components/atoms'

const CoverCard = ({ cover, point = 0 }) => {
  return (
    <Block position={'relative'} width={'100%'} borderRadius={'12px'}>
      <Image src={cover} width={'100%'} alt={'card'} />
      <Block
        height={'25%'}
        width={'90%'}
        m={'auto'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        background={'transparent'}
        position={'absolute'}
        left={0}
        right={0}
        bottom={0}
      >
        <Text variant={'subtitle1'}>Your point</Text>
        <Text variant={'h3'} color={'white'}>
          {point} Point
        </Text>
      </Block>
    </Block>
  )
}

// export default connect(
// 	(state) => ({
// 		brandBackend: selectBrands(state)
// 	}),
// 	null
// )(CoverCard);

export default CoverCard
