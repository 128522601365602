import React from 'react'
import { Block, Text, GatsbyImage } from 'components/atoms'
import LinearProgress from '@material-ui/core/LinearProgress'
import { lighten } from '@material-ui/core/styles'

const trophy = 'icon/trophy.png'

const TierProgress = ({ point, currentMember, nextMember }) => {
  const { name, pointRule: nextPointRule } = nextMember
  const { pointRule: currentPointRule } = currentMember
  const calculatePercent = () => {
    if (point < nextPointRule) {
      return Math.floor(
        ((point - currentPointRule) / (nextPointRule - currentPointRule)) * 100
      )
    } else return 100
  }

  return (
    <Block
      display={'flex'}
      alignItems={'center'}
      height={48}
      mb={40}
      position={'relative'}
    >
      <Block
        css={{
          '.MuiLinearProgress-colorPrimary': {
            backgroundColor: lighten(nextMember.color, 0.5),
          },
          '.MuiLinearProgress-barColorPrimary': {
            backgroundColor: nextMember.color,
          },
        }}
        width={'100%'}
        mr={36}
        position={'relative'}
      >
        <LinearProgress variant="determinate" value={calculatePercent() || 0} />
        <Block
          position={'absolute'}
          left={`${calculatePercent() || 0}%`}
          top={-3}
          backgroundColor={nextMember.color ? nextMember.color : 'primary'}
          height={10}
          width={10}
          borderRadius={10}
        />
        <Text
          mt={'16px'}
          position={'absolute'}
          left={`${
            calculatePercent() || 0 - 4 > 0 ? calculatePercent() || 0 - 4 : 0
          }%`}
          fontSize={10}
        >
          {point} P
        </Text>
      </Block>
      <Block
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}
        position={'absolute'}
        right={0}
        top={-18}
      >
        <Text fontSize={'8px'} height={12}>
          {name}
        </Text>
        <Text fontSize={'8px'} height={12}>
          {nextPointRule} Point
        </Text>
        <Block
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          background={nextMember.color ? nextMember.color : '#666666'}
          width={36}
          height={36}
          borderRadius={'50%'}
          m={'auto'}
        >
          <GatsbyImage src={trophy} width={20} height={20} />
        </Block>
      </Block>
    </Block>
  )
}

export default TierProgress
