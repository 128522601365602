import React from 'react'
import { Link } from 'gatsby'
import { Block, GatsbyImage } from 'components/atoms'
// import { Menu } from '@material-ui/icons';
const Logo = 'logo.png'

const Header = ({ boxShadow = false }) => {
  // const [setShowMobileMenu] = React.useState(false);
  return (
    <Block
      id={'header'}
      position={'initial'}
      background={'white'}
      top={0}
      css={{
        transition: '0.3s',
        zIndex: 1000,
        width: '100%',
        boxShadow: boxShadow ? '0 4px 2px -2px lightgrey' : 'none',
      }}
      maxHeight={['auto', '13vh']}
    >
      <Block
        display={'flex'}
        maxWidth={['initial', 700, 960, 1200]}
        mx={'auto'}
        alignItems={'center'}
        justifyContent={'initial'}
      >
        <Block.Middle
          px={[16, 0]}
          minHeight={[64, 80]}
          width={['100%', '100%']}
          position={'relative'}
        >
          {/* <Block
						display={['block', 'none', 'none']}
						css={{ '.MuiSvgIcon-root': { fontSize: 24 } }}
						position={'absolute'}
					>
						<Menu onClick={() => setShowMobileMenu(true)} />
					</Block> */}
          <Block width={['30%', '100%']} m={['auto', 'none']}>
            <Link to="/">
              <GatsbyImage
                src={Logo}
                width={[85, 100]}
                height={32}
                m={'auto'}
              />
            </Link>
          </Block>
        </Block.Middle>
      </Block>
    </Block>
  )
}

export default Header
