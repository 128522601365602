import React, { useEffect, useState } from 'react'
import firebase from 'appFirebase/config'
import { navigate } from 'gatsby'

import { Block, Text, Button, GatsbyImage } from 'components/atoms'
import get from 'lodash/get'
import PhoneOtp from 'components/forms/PhoneOtp'
import PhoneInput from 'components/forms/PhoneInput'
// CloudApi
import CloudApi from 'api/cloudApi'

// REDUX
import { getProfile } from 'modules/auth/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const MobileImage = 'link-mobile-warrix.png'

const PhoneStep = ({ profile, getProfile }) => {
  const [formState, setFormState] = useState('phone')
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [confirmationResult, setConfirmationResult] = useState(null)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (get(profile, 'phoneNumber')) {
      setFormState('warning')
    }
  }, [])

  return (
    <Block px={20} width={['auto', 720]} m={'auto'}>
      <Block mt={'8px'} height={21}>
        {formState === 'otp' && (
          <Text link onClick={() => setFormState('phone')}>
            {'< แก้ไขเบอร์โทร'}
          </Text>
        )}
      </Block>
      <Block mt={20} mb={40} mx={'auto'} width={['100%', '80%']}>
        <GatsbyImage src={MobileImage} imgStyle={{ objectFit: 'cover' }} />
      </Block>
      <Block>
        {formState === 'phone' && (
          <PhoneInput
            updateState={({ confirmationResult, phoneNumber }) => {
              setFormState('otp')
              setConfirmationResult(confirmationResult)
              setPhoneNumber(phoneNumber)
            }}
            profile={profile}
          />
        )}
        {formState === 'otp' && (
          <PhoneOtp
            phoneNumber={phoneNumber}
            confirmationResult={confirmationResult}
            setConfirmationResult={setConfirmationResult}
            getProfile={getProfile}
            setFormState={setFormState}
          />
        )}
        {formState === 'warning' && (
          <Block textAlign={'center'}>
            <Text variant={'h2'}>คำเตือน</Text>
            <Text mt={20}>
              คุณได้ทำการผูกเบอร์โทรศัพท์ {get(profile, 'phoneNumber')}{' '}
              ไว้อยู่แล้ว คุณต้องการทีจะยกเลิกเบอร์นี้ใช่หรือไม่
            </Text>
            <Block display={'flex'} mt={25} justifyContent={'flex-end'}>
              <Button.OutLined onClick={() => navigate('/')} mr={25}>
                ยกเลิก
              </Button.OutLined>
              <Button.Contain
                loading={pending}
                onClick={() => {
                  setPending(true)
                  return CloudApi.unLinkPhoneNumber().then(() => {
                    getProfile()
                    setPending(false)
                    setFormState('phone')
                  })
                }}
              >
                ตกลง
              </Button.Contain>
            </Block>
          </Block>
        )}
      </Block>
    </Block>
  )
}

export default connect(
  null,
  dispatch =>
    bindActionCreators(
      {
        getProfile,
      },
      dispatch
    )
)(PhoneStep)
