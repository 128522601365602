import React from 'react'
import get from 'lodash/get'
import { navigate } from 'gatsby'
import moment from 'moment'
import { uploadFile } from 'appFirebase/storage'
import { Block, Text, Image, Button, Loader } from 'components/atoms'
import {
  EditProfileModal,
  ChangePasswordModal,
  AddressSection,
} from 'components/organisms'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import Divider from '@material-ui/core/Divider'
import Camera from '@material-ui/icons/CameraAltOutlined'
import firebase from 'appFirebase/config'

// CloudApi
import CloudApi from 'api/cloudApi'

// REDUX
import { getProfile, logout } from 'modules/auth/actions'
import { clearHistory } from 'modules/history/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import 'moment/locale/th'

const PersonalInfo = ({
  profile,
  getProfile,
  addresses,
  logout,
  clearHistory,
}) => {
  const {
    photoURL,
    displayName,
    email,
    birthDate,
    gender,
    phoneNumber,
    uid,
  } = profile
  const [openEditProfile, setOpenEditProfile] = React.useState(false)
  const [openChangePasswordModal, setOpenChangePasswordModal] = React.useState(
    false
  )
  const [editType, setEditType] = React.useState(false)
  const [loadingInUploadImage, setLoadingInUploadImage] = React.useState(false)

  const renderGender = gender => {
    if (gender) {
      if (gender === 'male') return 'ชาย'
      else if (gender === 'female') return 'หญิง'
      else if (gender === 'other') return 'อื่นๆ'
      else return 'ไม่ระบุ'
    }
    return null
  }

  const hasPassword = () => {
    const providerData = get(firebase.auth().currentUser, 'providerData', [])
    const providerPassword = providerData.filter(
      providerData => providerData.providerId === 'password'
    )
    return providerPassword.length !== 0
  }

  return (
    <Block>
      <Block
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        wordBreak={'break-word'}
        css={{
          '.MuiList-root': { width: '100%' },
          '.MuiSvgIcon-root': {
            fontSize: 25,
            color: 'lightgrey',
          },
        }}
        width={['auto', '520px']}
        maxWidth={['auto', '520px']}
        mx={'auto'}
      >
        <Block background={'white'} position={'relative'} mt={[20, 0]}>
          <Image
            src={photoURL}
            objectFit={'cover'}
            borderRadius={'50%'}
            height={[95, 160]}
            width={[95, 160]}
          />
          <label>
            <Block
              className={'upload-label'}
              width={[95, 160]}
              height={[46, 84]}
              left={'0px'}
              top={[49, 76]}
              css={{
                '.MuiSvgIcon-root': {
                  fontSize: 30,
                  color: 'rgba(255, 255, 255, .7)',
                  position: 'absolute',
                  top: 20,
                  left: 70,
                  '@media (max-width: 640px)': {
                    fontSize: 22,
                    top: 14,
                    left: 36,
                  },
                },
              }}
              style={{
                display: 'inline-block',
                borderBottomLeftRadius: 170,
                borderBottomRightRadius: 170,
                position: 'absolute',
                background: 'rgba(0, 0, 0, .3)',
                cursor: 'pointer',
              }}
            >
              <input
                type="file"
                onChange={({ target }) => {
                  setLoadingInUploadImage(true)
                  uploadFile(target.files[0], `users/${uid}`, 'photoUrl').then(
                    photoURL =>
                      CloudApi.updateUserProfile({ photoURL }).then(() =>
                        getProfile().then(() => {
                          setLoadingInUploadImage(false)
                        })
                      )
                  )
                }}
                accept=".gif,.jpg,.jpeg,.png"
                style={{ display: 'none' }}
                multiple
              />
              <Camera />
            </Block>
          </label>
        </Block>
        <Block
          display={'flex'}
          alignItems={'center'}
          onClick={() => {
            setOpenEditProfile(true)
            setEditType('displayName')
          }}
          style={{ cursor: 'pointer' }}
        >
          <Text mt={10} mb={'10px'} mr={'15px'}>
            <h2>{displayName}</h2>
          </Text>
          <ArrowForwardIos />
        </Block>
        <List component="nav">
          <ListItem>
            <Block display={'flex'} width={'100%'} alignItems={'center'}>
              <Text variant={'h4'} width={'30%'}>
                อีเมล
              </Text>
              <Text
                style={{ textOverflow: 'ellipsis' }}
                variant={'caption'}
                overflow={'hidden'}
                width={'60%'}
                pr={25}
              >
                {email}
              </Text>
            </Block>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              setOpenEditProfile(true)
              setEditType('birthday')
            }}
            disabled={
              get(profile, 'birthDate') &&
              !!moment(birthDate).format('DD MMM YYYY')
            }
          >
            <Block display={'flex'} width={'100%'} alignItems={'center'}>
              <Text variant={'h4'} width={'30%'}>
                วันเกิด
              </Text>
              <Text variant={'caption'} width={'60%'}>
                {get(profile, 'birthDate') &&
                  (moment(birthDate).format('DD MMM YYYY') || '-')}
              </Text>
              <Block ml={'auto'}>
                <ArrowForwardIos />
              </Block>
            </Block>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              setOpenEditProfile(true)
              setEditType('gender')
            }}
            disabled={!!gender}
          >
            <Block display={'flex'} width={'100%'} alignItems={'center'}>
              <Text variant={'h4'} width={'30%'}>
                เพศ
              </Text>
              <Text variant={'caption'} width={'60%'}>
                {renderGender(gender) || '-'}
              </Text>
              <Block ml={'auto'}>
                <ArrowForwardIos />
              </Block>
            </Block>
          </ListItem>
          <Divider />
          <ListItem button onClick={() => navigate('/phone')}>
            <Block display={'flex'} width={'100%'} alignItems={'center'}>
              <Text variant={'h4'} width={'30%'}>
                เบอร์มือถือ
              </Text>
              <Text variant={'caption'} width={'60%'}>
                {phoneNumber || '-'}
              </Text>
              <Block ml={'auto'}>
                <ArrowForwardIos />
              </Block>
            </Block>
          </ListItem>
          <Divider />
          {hasPassword() && (
            <Block>
              <ListItem button onClick={() => setOpenChangePasswordModal(true)}>
                <Block display={'flex'} width={'100%'} alignItems={'center'}>
                  <Text variant={'h4'} width={'30%'}>
                    รหัสผ่าน
                  </Text>
                  <Text variant={'caption'} width={'60%'} letterSpacing={2}>
                    ••••••••
                  </Text>
                  <Block ml={'auto'}>
                    <ArrowForwardIos />
                  </Block>
                </Block>
              </ListItem>
              <Divider />
            </Block>
          )}
        </List>
      </Block>
      <AddressSection profile={profile} addresses={addresses} />
      <Block width={'inherit'}>
        <Block textAlign={'center'} px={[20, 0]}>
          <Button.OutLined
            mt={[20, 35]}
            width={['100%', 'auto']}
            onClick={() => {
              const windowGlobal = typeof window !== 'undefined' && window
              const { localStorage } = windowGlobal
              return firebase
                .auth()
                .signOut()
                .then(() => {
                  logout()
                  clearHistory()
                  localStorage.setItem('alreadyGoToPhonePage', 'reset')
                  return navigate('/login')
                })
            }}
          >
            <Text>ออกจากระบบ</Text>
          </Button.OutLined>
        </Block>
      </Block>
      <EditProfileModal
        open={openEditProfile}
        profile={profile}
        type={editType}
        onClose={() => setOpenEditProfile(false)}
      />
      <ChangePasswordModal
        open={openChangePasswordModal}
        onClose={() => setOpenChangePasswordModal(false)}
      />
      {loadingInUploadImage && <Loader />}
    </Block>
  )
}

export default connect(
  null,
  dispatch =>
    bindActionCreators(
      {
        getProfile,
        clearHistory,
        logout,
      },
      dispatch
    )
)(PersonalInfo)
