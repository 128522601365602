import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// COMPONENTS
import { Text, GatsbyImage } from 'components/atoms'
import Processing from './Processing'
import Modal from '../Modal/Modal'

// PICTURE
const successPicture = 'success.png'
const dangerPicture = 'danger.png'

const icons = {
  success: successPicture,
  danger: dangerPicture,
}

const StyledModal = styled(Modal)({
  '.box': {
    textAlign: 'center',
  },
})

class Alert extends Component {
  render() {
    const { open, onClose, title, description, status } = this.props
    return (
      <StyledModal
        open={open}
        onClose={onClose}
        content={
          <div className={'box'}>
            <GatsbyImage m={'auto'} mb={15} width={70} src={icons[status]} />
            <Text fontWeight={'bold'} fontSize={[20, 25]}>
              {title}
            </Text>
            <Text fontSize={20} fontWeight={'semiBold'}>
              {description}
            </Text>
          </div>
        }
      />
    )
  }
}

Alert.Processing = Processing
Alert.NOT_ALLOWED = 'ไม่สามารถทำรายการได้'
Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.oneOf(['success', 'danger']),
}
Alert.defaultProps = {
  title: 'Success',
  description: '',
  status: 'success',
}

export default Alert
