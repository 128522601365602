import React from 'react'
import { Block } from 'components/atoms'
import RewardCard from 'components/molecules/RewardCard'

const RewardSection = ({ rewards = [], setReward }) => {
  return (
    <Block>
      <Block p={20} overflowX={'auto'} display={['flex']}>
        {rewards.map(reward => (
          <Block
            key={reward.id}
            mr={30}
            style={{ cursor: 'pointer' }}
            onClick={() => setReward(reward)}
          >
            <RewardCard reward={reward} />
          </Block>
        ))}
      </Block>
    </Block>
  )
}

export default RewardSection
