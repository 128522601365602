import React from 'react'
import { Block, Text, Button } from 'components/atoms'
import { Modal } from 'components/molecules'

const DeleteAddressModal = ({
  onClose,
  open,
  deleteAddress,
  deleteAddressStatus,
  addressId,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      content={
        <Block width={['auto', 400]}>
          <Text variant={'h3'} textAlign={'center'}>
            คุณต้องการลบที่อยู่นี้ใช่หรือไม่?
          </Text>
          <Block
            display={'flex'}
            mt={25}
            justifyContent={'center'}
            width={'100%'}
          >
            <Button.Contain
              mr={20}
              onClick={() => deleteAddress(addressId).then(() => onClose())}
              loading={deleteAddressStatus.isPending}
            >
              ตกลง
            </Button.Contain>
            <Button.OutLined onClick={() => onClose()}>ยกเลิก</Button.OutLined>
          </Block>
        </Block>
      }
    />
  )
}

export default DeleteAddressModal
