import React from 'react'
import get from 'lodash/get'
import { compose } from 'recompose'
import { navigate } from 'gatsby'
import { Block, Loader, Text } from 'components/atoms'
import { CoverCard, Modal } from 'components/molecules'
import { db, auth } from '../appFirebase/config'
import withTheme from 'hocs/withTheme'

// REDUX
import { getProfile } from 'modules/auth/actions'
import { connect } from 'react-redux'
import {
  selectGetAuthStatus,
  selectAuthData,
  selectAddressData,
} from 'modules/auth/selectors'
import { bindActionCreators } from 'redux'

const WithProfile = WrappedComponent => {
  class CheckAuthProfile extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        transactionData: null,
        shouldGetProfile: true,
        phoneNumber: null,
        member: null,
        event: null,
      }
    }

    componentDidMount() {
      // localStorage.setItem('showPhoneModal', 'null');
      const { getProfile, profile,location } = this.props
      const windowGlobal = typeof window !== 'undefined' && window
      const { localStorage } = windowGlobal

      auth.onAuthStateChanged(user => {
        if (user) {
          if (!profile) {
            getProfile().then(({ value }) => {
              const phoneNumber = get(value, 'phoneNumber', null)
              if (
                !phoneNumber &&
                localStorage.getItem('alreadyGoToPhonePage') !== 'alreadyGo'
              )
                navigate('/phone', { state: { skipPage: true } })
              db.ref(value.uid).on('value', snapshot => {
                console.log(snapshot.val())
                this.setState({ member: snapshot.val() })
              })
            })
          }
        } else {
          return navigate(`/login/${location.search}`)
        }
      })
    }

    render() {
      const { profile, addresses } = this.props
      const { member } = this.state
      if (!profile)
        return (
          <Block.MiddleCenter flex={1}>
            <Loader />
          </Block.MiddleCenter>
        )
      else {
        return (
          <>
            <Modal
              open={member}
              onClose={() => {
                this.setState({ member: null })
                db.ref(profile.uid).set({})
              }}
              content={
                member && (
                  <Block width={['auto', 400]}>
                    <Text variant={'h2'} textAlign={'center'}>
                      คุณได้เลื่อนระดับเป็นสมาชิกระดับ {member.name}
                    </Text>
                    <Text textAlign={'center'} mt={'8px'}>
                      จากการสะสมแต้มถึง {member.pointRule} แต้ม
                    </Text>
                    <Block mt={'16px'}>
                      <CoverCard cover={member.image} point={profile.point} />
                    </Block>
                  </Block>
                )
              }
            />

            <WrappedComponent
              {...this.props}
              profile={profile}
              getProfile={getProfile}
              addresses={addresses}
            />
          </>
        )
      }
    }
  }

  const mapStateToProps = state => ({
    getProfileStatus: selectGetAuthStatus(state),
    profile: selectAuthData(state),
    addresses: selectAddressData(state),
  })
  return compose(
    connect(
      mapStateToProps,
      dispatch =>
        bindActionCreators(
          {
            getProfile,
          },
          dispatch
        )
    ),
    withTheme()
  )(CheckAuthProfile)
}

export default WithProfile
