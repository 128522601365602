import React from 'react'
import { Modal } from 'components/molecules'
import { Block, Text, Button, GatsbyImage } from 'components/atoms'

// Image
const Line = 'line-me.png'

const LoginLineModal = ({ open, onClose, getLineUrl }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <Block>
          <Text>
            อีเมลล์นี้ไม่สามารถใช้งานได้ เนื่องจากเคยถูกใช้ในการ login ผ่าน line
            ไปแล้ว
          </Text>
          <Text mt={[15, 20]} textAlign={'center'}>
            กรุณา login ผ่าน line
          </Text>
          <Button.Contain
            color={'line'}
            fullWidth
            background={'#1bb71f !important'}
            borderRadius={30}
            textAlign={'center'}
            position={'relative'}
            mt={20}
            href={getLineUrl()}
            onClick={() => {
              window.location = `${getLineUrl()}`
            }}
          >
            <Block position={'relative'}>
              <GatsbyImage
                src={Line}
                width={[25, 30]}
                height={[25, 30]}
                position={'absolute'}
                top={['3px', '-2px']}
                left={[-35, -40]}
              />
              <Text fontWeight={'bold'} color={'white'} lineHeight={1.75}>
                Log in with LINE
              </Text>
            </Block>
          </Button.Contain>
        </Block>
      }
    />
  )
}

export default LoginLineModal
