import React, { useState, useEffect } from 'react'
import Divider from '@material-ui/core/Divider'
import { Block, Text, Button, Image } from 'components/atoms'
import RedeemRewardModal from '../RedeemRewardModal'
import RedeemRewardSuccessModal from '../RedeemRewardSuccessModal'
import { animateScroll as scroll } from 'react-scroll'

const RewardDetail = ({ reward, backToCard, userPoint }) => {
  useEffect(() => {
    scroll.scrollToTop({
      duration: 750,
      smooth: true,
    })
  }, [])

  const { name, point, image, conditions } = reward
  const [openRedeemModal, setOpenRedeemModal] = useState(false)
  const [openRedeemSuccessModal, setOpenRedeemSuccessModal] = useState(false)

  return (
    <Block p={20}>
      <Text onClick={() => backToCard()} link>
        {'< กลับ'}
      </Text>
      <Image
        src={image}
        minHeight={300}
        maxHeight={350}
        mt={20}
        ml={'auto'}
        mr={'auto'}
        mb={20}
      />
      <Text variant={'h1'} mb={15}>
        {name}
      </Text>
      <Text variant={'caption'}>{point} point</Text>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <Text variant={'h4'} mb={10}>
        เงื่อนไขการแลกของรางวัล
      </Text>
      <Block>
        {conditions.map((condition, index) => (
          <Block>
            <Text variant={'caption'} mt={14}>
              {index + 1}. {condition}
            </Text>{' '}
          </Block>
        ))}
      </Block>
      <Block textAlign={'center'} mt={30}>
        <Button.Contain
          width={['100%', 370]}
          disabled={userPoint < point}
          onClick={() => setOpenRedeemModal(true)}
        >
          แลกของรางวัล
        </Button.Contain>
      </Block>
      <RedeemRewardModal
        onClose={() => setOpenRedeemModal(false)}
        open={openRedeemModal}
        reward={reward}
        userPoint={userPoint}
        setOpenRedeemSuccessModal={setOpenRedeemSuccessModal}
      />
      <RedeemRewardSuccessModal
        open={openRedeemSuccessModal}
        reward={reward}
        userPoint={userPoint}
        onClose={() => setOpenRedeemSuccessModal(false)}
      />
    </Block>
  )
}

export default RewardDetail
