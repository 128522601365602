import axios from 'axios'
import firebase from 'appFirebase/config'
import { apiUrl } from 'helpers/constants'

export default class CloudApi {
  static request(config) {
    return axios({
      method: 'GET',
      ...config,
      baseURL: `${apiUrl}/api`,
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
    })
      .then(({ data }) => data)
      .catch(({ response, ...other }) => {
        if (response) {
          throw response.data
        }
        // eslint-disable-next-line
        throw { error: other }
      })
  }

  static requestWithCredential(config) {
    const user = firebase.auth().currentUser
    if (user) {
      return user.getIdToken().then(idToken => {
        return CloudApi.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${idToken}`,
          },
        })
      })
    } else {
      throw new Error('Not login')
    }
  }

  static getProfile() {
    return CloudApi.requestWithCredential({
      url: '/auth/me',
    })
  }

  static registerWithFacebook(data) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/users/register/facebook',
      data,
    })
  }

  static register(data) {
    return CloudApi.request({
      url: '/auth/register/email',
      method: 'POST',
      data,
    })
  }

  static loginFacebook(data) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/auth/login/facebook',
      data,
    })
  }

  static loginWithGuest(data) {
    // console.log('data', data);
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/users/login/guest',
      data,
    })
  }

  static loginWithLine(data, accessToken) {
    return CloudApi.request({
      method: 'POST',
      url: '/auth/login-line',
      headers: {
        Authorization: accessToken,
      },
      data,
    })
  }

  static getNewNotification(id) {
    return CloudApi.requestWithCredential({
      url: `/notifications/${id}`,
    })
  }

  static updateProfile(data) {
    return CloudApi.requestWithCredential({
      url: '/users/me/basic-profile',
      method: 'PUT',
      data,
    })
  }

  static loginByPhoneNumber(data) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/auth/login/phone-number',
      data,
    })
  }

  static updatePhoneNumber(data) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/auth/update/phone-number',
      data,
    })
  }

  static unLinkPhoneNumber() {
    return CloudApi.requestWithCredential({
      method: 'PUT',
      url: '/auth/update/unlink-phone',
    })
  }

  static updateUserProfile(data) {
    return CloudApi.requestWithCredential({
      method: 'PUT',
      url: '/auth/update/user-profile',
      data,
    })
  }

  static collectPoint(redeemCodes) {
    const fixCodes = redeemCodes.filter(code =>
      ['CH', 'PL', 'RE'].includes(code.substring(0, 2))
    )
    const normalCodes = redeemCodes.filter(
      code => !['CH', 'PL', 'RE'].includes(code.substring(0, 2))
    )
    if (fixCodes.length > 0) {
      return Promise.all([
        CloudApi.requestWithCredential({
          method: 'POST',
          url: '/campaign/redeem/campaign',
          data: {
            redeemCodes: normalCodes,
          },
        }),
        CloudApi.redeemFixCode(fixCodes),
      ]).then(([redeemNormalCodesData, redeemFixCodesData]) =>
        redeemNormalCodesData.concat(redeemFixCodesData)
      )
    } else {
      return CloudApi.requestWithCredential({
        method: 'POST',
        url: '/campaign/redeem/campaign',
        data: {
          redeemCodes: normalCodes,
        },
      })
    }
  }

  static redeemFixCode(redeemCodes) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/campaign/redeem-fix-code',
      data: {
        redeemCodes,
      },
    })
  }

  static updateUserPoint(point) {
    return CloudApi.requestWithCredential({
      method: 'PUT',
      url: '/auth/update/user-point',
      data: {
        point,
      },
    })
  }

  static getHistory(lastMoment) {
    return CloudApi.requestWithCredential({
      url: `/history/me?${lastMoment ? `after=${lastMoment}` : ''}`,
    })
  }

  static getTransactions(lastMoment) {
    return CloudApi.requestWithCredential({
      url: `/history/transactions/me?${
        lastMoment ? `after=${lastMoment}` : ''
      }`,
    })
  }

  static getRedemptions(lastMoment) {
    return CloudApi.requestWithCredential({
      url: `/history/redemptions/me?${lastMoment ? `after=${lastMoment}` : ''}`,
    })
  }

  static getMemberData() {
    return CloudApi.requestWithCredential({
      url: '/member',
    })
  }

  static getGeo() {
    return CloudApi.requestWithCredential({
      url: '/geo',
    })
  }

  static createAddress(address) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: '/users/create-address',
      data: {
        ...address,
      },
    }).then(() => CloudApi.getAddress())
  }

  static getAddress() {
    return CloudApi.requestWithCredential({
      url: '/users/address',
    })
  }

  static deleteAddress(addressId) {
    return CloudApi.requestWithCredential({
      method: 'DELETE',
      url: `/users/delete-address/${addressId}`,
    }).then(() => CloudApi.getAddress())
  }

  static editAddress(address) {
    return CloudApi.requestWithCredential({
      method: 'PUT',
      url: `/users/edit-address/${address.id}`,
      data: { ...address },
    }).then(() => CloudApi.getAddress())
  }

  static getReward() {
    return CloudApi.requestWithCredential({
      url: '/reward',
    })
  }

  static redeemReward(data) {
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: `/campaign/redeem-reward`,
      data,
    })
  }

  static joinEvent(eventCode){
    return CloudApi.requestWithCredential({
      method: 'POST',
      url: `/event/redeem-eventCode`,
      data:{
        eventCode
      },
    })
  }
}
