import HISTORY_TYPES from './types'
import nth from 'lodash/nth'
import CloudApi from '../../api/cloudApi'
import {
  selectHistoryData,
  selectTransactionData,
  selectRedemptionData,
} from './selectors'

export const getTransactions = refresh => (dispatch, getState) => {
  const state = getState()
  const transactions = selectTransactionData(state)
  const lastItem = nth(transactions, -1)
  const lastMoment = !refresh && lastItem ? lastItem.createdAt : null
  return dispatch({
    type: HISTORY_TYPES.GET_HISTORY_TRANSACTION,
    payload: CloudApi.getTransactions(lastMoment),
    meta: !refresh && lastItem,
  })
}

export const getRedemptions = refresh => (dispatch, getState) => {
  const state = getState()
  const redemptions = selectRedemptionData(state)
  const lastItem = nth(redemptions, -1)
  const lastMoment = !refresh && lastItem ? lastItem.createdAt : null
  return dispatch({
    type: HISTORY_TYPES.GET_HISTORY_REDEMPTION,
    payload: CloudApi.getRedemptions(lastMoment),
    meta: !refresh && lastItem,
  })
}

export const clearHistory = () => dispatch => {
  return dispatch({
    type: HISTORY_TYPES.CLEAR_HISTORY,
  })
}
