import React from 'react'
import { Block, Text, GatsbyImage } from 'components/atoms'
import Chip from '@material-ui/core/Chip'

// ICONS
import Check from '@material-ui/icons/Check'

const locationIcon = 'icon/location.png'
const phoneIcon = 'icon/phone.png'
const trashIcon = 'icon/trash.png'
const editIcon = 'icon/edit.png'

const AddressCard = ({
  address,
  editable,
  deletable,
  selectedAddressId,
  setOpenDeleteModal,
  setEditAddress,
}) => {
  const checkBorderStyle = () => {
    if (selectedAddressId) {
      return selectedAddressId === address.id
    }
    return address.defaultAddress
  }

  return (
    <Block
      p={16}
      maxWidth={['auto', 300]}
      height={'auto'}
      borderRadius={8}
      boxShadow={'0 1px 10px 0 rgba(0, 0, 0, 0.1)'}
      mr={[0, 20]}
      mb={[20, 0]}
      flex={'0 0 auto'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-around'}
      position={'relative'}
      border={checkBorderStyle() ? '1px solid #ee5e3e' : 'none'}
    >
      <Block mb={'8px'}>
        <Text variant={'h4'} mb={15}>
          {address.receiver}
        </Text>
        <Block display={'flex'} alignItems={'flex-start'}>
          <GatsbyImage height={20} width={20} mr={'8px'} src={locationIcon} />
          <Text flex={1} variant={'caption'}>
            {address.address} {address.district} {address.subDistrict}{' '}
            {address.province} {address.postcode}
          </Text>
        </Block>
        <Block display={'flex'} alignItems={'center'}>
          <GatsbyImage height={20} width={20} mr={'8px'} src={phoneIcon} />
          <Text flex={1} variant={'caption'}>
            {address.phone}
          </Text>
        </Block>
      </Block>
      <Block display={'flex'} justifyContent={'flex-end'}>
        {editable && (
          <GatsbyImage
            src={editIcon}
            width={25}
            height={25}
            mr={10}
            style={{ cursor: 'pointer' }}
            onClick={() => setEditAddress()}
          />
        )}
        {deletable && (
          <GatsbyImage
            src={trashIcon}
            width={25}
            height={25}
            onClick={() => setOpenDeleteModal()}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Block>
      {checkBorderStyle() && (
        <Block position={'absolute'} top={10} right={15}>
          {!selectedAddressId ? (
            <Chip size="small" label="ที่อยู่หลัก" color="primary" />
          ) : (
            <Block
              background={'#ee5e3e'}
              p={'3px'}
              borderRadius={'50%'}
              display={'flex'}
            >
              <Check style={{ color: 'white', fontSize: 20 }} />
            </Block>
          )}
        </Block>
      )}
    </Block>
  )
}

export default AddressCard
