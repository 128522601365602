import React from 'react'

import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { ThemeProvider } from '@material-ui/styles'
import muiTheme from '../theme/muiTheme'
import baseTheme from '../theme/baseTheme'

export default () => WrappedComponent => props => (
  <EmotionThemeProvider theme={baseTheme}>
    <ThemeProvider theme={muiTheme}>
      <WrappedComponent {...props} />
    </ThemeProvider>
  </EmotionThemeProvider>
)
