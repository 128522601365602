import types from './types'
import CloudApi from '../../api/cloudApi'

export const startObserveAuth = () => ({
  type: types.OBSERVE_AUTH_PENDING,
})

export const observeAuthSuccess = payload => ({
  type: types.OBSERVE_AUTH_FULFILLED,
  payload,
})

export const observeAuthFailure = payload => ({
  type: types.OBSERVE_AUTH_REJECTED,
  payload,
})

const registerRequest = () => ({
  type: types.REGISTER_WITH_FACEBOOK_PENDING,
})

const registerSuccess = payload => ({
  type: types.REGISTER_WITH_FACEBOOK_FULFILLED,
  payload,
})
const registerFailure = payload => ({
  type: types.REGISTER_WITH_FACEBOOK_REJECTED,
  payload,
})

export const fetchMyCardsSuccess = payload => ({
  type: types.FETCH_MY_CARDS_SUCCESS,
  payload,
})

export const registerWithFacebook = data => dispatch => {
  dispatch(registerRequest())
  return CloudApi.registerWithFacebook(data)
    .then(data => dispatch(registerSuccess(data)))
    .catch(err => dispatch(registerFailure(err)))
}

export const getProfile = () => ({
  type: types.GET_PROFILE,
  payload: CloudApi.getProfile(),
})

export const getAddress = () => ({
  type: types.GET_ADDRESS,
  payload: CloudApi.getAddress(),
})

export const creatAddress = address => ({
  type: types.CREATE_ADDRESS,
  payload: CloudApi.createAddress(address),
})

export const deleteAddress = addressId => ({
  type: types.DELETE_ADDRESS,
  payload: CloudApi.deleteAddress(addressId),
})

export const editAddressData = address => ({
  type: types.EDIT_ADDRESS,
  payload: CloudApi.editAddress(address),
})

export const logout = () => ({
  type: types.LOG_OUT,
})
