import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Block, Text } from 'components/atoms'

const Loader = styled(({ text, className }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <Block.MiddleCenter
          className={cx('loader', className)}
          flexDirection={'column'}
        >
          <Block position={'relative'}>
            <CircularProgress size={100} thickness={1} />
            {/* <Image
              css={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-49%, -53%)",
              }}
              width={60}
              src={
                "https://firebasestorage.googleapis.com/v0/b/mi-crm-pumpkin-admin.appspot.com/o/assets%2FHead.png?alt=media"
              }
            /> */}
          </Block>
          <Text
            color={'darkGrey'}
            letterSpacing={'1px'}
            fontWeight={'light'}
            mt={12}
            fontSize={2}
          >
            {text}
          </Text>
        </Block.MiddleCenter>
      )}
    </ClassNames>
  )
})(({ float }) => ({
  ...(float && {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.87)',
    zIndex: 100,
  }),
}))

Loader.propTypes = {
  float: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
}
Loader.defaultProps = {
  float: true,
  className: '',
  text: 'Loading...',
}

export default Loader
