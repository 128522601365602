import React, { useEffect, useState } from 'react'
import { Block, Button, Loader, Text, Image } from 'components/atoms'
import { Modal } from 'components/molecules'
import { AddressCard } from 'components/molecules'
import AddressForm from 'components/forms/AddressForm'

// REDUX
import { getGeo } from 'modules/geo/actions'
import { connect } from 'react-redux'
import { selectAddressData, selectGetAuthStatus } from 'modules/auth/selectors'
import { redeemReward } from 'modules/reward/actions'
import { getRedemptions } from 'modules/history/actions'
import { getProfile } from 'modules/auth/actions'
import { selectRedeemRewardStatus } from 'modules/reward/selectors'
import { selectGeo, selectGetGeoStatus } from 'modules/geo/selectors'
import { bindActionCreators } from 'redux'

// ICONS
import Add from '@material-ui/icons/Add'

const RedeemRewardModal = ({
  reward,
  open,
  geo,
  getGeo,
  getGeoStatus,
  addresses,
  onClose,
  redeemReward,
  redeemRewardStatus,
  getProfile,
  getProfileStatus,
  setOpenRedeemSuccessModal,
  getRedemptions,
}) => {
  const [state, setState] = useState('showAddress')
  const [editAddress, setEditAddress] = useState(null)
  const [selectedAddressId, setSelectedAddressId] = useState(null)
  const [redeemSuccess, setRedeemSuccess] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!geo) {
      getGeo()
    }
    if (addresses.length > 0) {
      setSelectedAddressId(
        addresses.find(address => address.defaultAddress).id || addresses[0]
      )
    }

    if (addresses.length === 0) {
      setState('addOrEditAddress')
    }
  }, [addresses])

  const { image, name, point } = reward
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
        if (redeemSuccess) {
          setRedeemSuccess(false)
        }
        if (error) {
          setError(null)
        }
      }}
      content={
        <Block width={['auto', 360]}>
          <Block>
            <Text variant={'h2'}>แลกรับรางวัล!</Text>
            <Block display={'flex'} mt={20}>
              <Image src={image} width={75} height={75} borderRadius={8} />
              <Block
                ml={20}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <Text variant={'h4'}>{name}</Text>
                <Text color={'#787878'} fontSize={13} fontWeight={'bold'}>
                  {point} points
                </Text>
              </Block>
            </Block>
            {state === 'showAddress' && (
              <Block>
                <Text mt={20} color={'#acacac'}>
                  กรุณาเลือกที่อยู่เพื่อจัดส่งของรางวัล
                </Text>
                {addresses.map(address => (
                  <Block
                    mt={20}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedAddressId(address.id)}
                  >
                    <AddressCard
                      address={address}
                      height={120}
                      editable
                      width={'auto'}
                      setEditAddress={() => {
                        setEditAddress(address)
                        setState('addOrEditAddress')
                      }}
                      selectedAddressId={selectedAddressId}
                    />
                  </Block>
                ))}
                <Block
                  mt={25}
                  display={'flex'}
                  alignitems={'center'}
                  justifyContent={'center'}
                  p={15}
                  border={'1px dashed #bababa'}
                  color={'#bababa'}
                  borderRadius={8}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setState('addOrEditAddress')}
                >
                  <Add style={{ fontSize: 19 }} />
                  <Text ml={10} fontSize={13}>
                    เพิ่มที่อยู่อื่น
                  </Text>
                </Block>
                {error && (
                  <Text mt={15} mb={15} color={'#ff5252'} fontSize={15}>
                    มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่ในภายหลัง
                  </Text>
                )}
                <Button.Contain
                  fullWidth
                  mt={25}
                  disabled={addresses.length === 0 || !setSelectedAddressId}
                  loading={
                    redeemRewardStatus.isPending || getProfileStatus.isPending
                  }
                  onClick={() =>
                    redeemReward({
                      rewardId: reward.id,
                      address: addresses.find(
                        address => address.id === selectedAddressId
                      ),
                    })
                      .then(() =>
                        getProfile().then(() => {
                          getRedemptions(true)
                          onClose()
                          setOpenRedeemSuccessModal(true)
                        })
                      )
                      .catch(() => setError(true))
                  }
                >
                  แลกของรางวัล
                </Button.Contain>
              </Block>
            )}
          </Block>
          {state === 'addOrEditAddress' && (
            <AddressForm
              geo={geo}
              hasCancelButton
              closeForm={() => {
                setState('showAddress')
              }}
              editAddress={editAddress}
              defaultAddress={addresses.length === 0}
              inRedeemRewardModal
              canEditDefaultAddress={addresses.length > 1}
            />
          )}
          {getGeoStatus.isPending && <Loader />}
        </Block>
      }
    />
  )
}

const mapStateToProps = state => ({
  geo: selectGeo(state),
  redeemRewardStatus: selectRedeemRewardStatus(state),
  getGeoStatus: selectGetGeoStatus(state),
  addresses: selectAddressData(state),
  getProfileStatus: selectGetAuthStatus(state),
})

export default connect(
  mapStateToProps,
  dispatch =>
    bindActionCreators(
      {
        getGeo,
        redeemReward,
        getProfile,
        getRedemptions,
      },
      dispatch
    )
)(RedeemRewardModal)
