const website = process.env.WEBSITE_URL

export const apiUrl = process.env.API_URL
export const LineURL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&state=line-login&client_id=1653342141&redirect_uri=${apiUrl}/api/auth/line&redirectWeb=${website}/login&scope=openid email profile`

const genState=({eventCode}) => `eventCode:${eventCode};`

export const genLineURL =({eventCode}) =>`https://access.line.me/oauth2/v2.1/authorize?response_type=code
&state=${genState({eventCode})}&client_id=1653342141&redirect_uri=${apiUrl}/api/auth/line
&redirectWeb=${website}/login&scope=openid email profile`
