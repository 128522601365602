import get from 'lodash/get'

const baseTheme = {
  fontWeights: {
    ultraLight: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    semiBold: 500,
    bold: 600,
    extraBold: 700,
    ultraBold: 800,
    black: 900,
  },
  globalShadows: {
    baseShadow: '0 5px 20px 0 rgba(0,0,0,0.3)',
    lightShadow: '0 5px 20px 0 rgba(0,0,0,0.1)',
  },
  colors: {
    white: '#ffffff',
    extraLightGrey: '#f5f5f5',
    veryLightGrey: '#e5e5e5',
    lightGrey: '#b5b5b5',
    grey: '#888888',
    darkGrey: '#555555',
    black: 'rgba(0,0,0,0.87)',
    primary: '#EF5B34',
    lightPrimary: '#fff7ee',
    secondary: '#EF5B34',
    delete: '#ff5252',
    success: '#38D203',
    danger: '#ff5252',
    line: '#1bb71f',
    facebook: '#1777f3',
    containedColor: '#ffffff',
  },
  palette: {
    type: 'light',
    primary: {
      light: '#FC6238',
      main: '#EF5B34',
      dark: '#D6532F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff5252',
      light: 'FF9E9E',
      dark: '#CC4242',
      contrastText: '#ffffff',
    },
    delete: {
      main: '#ff5252',
      light: '#ffc346',
      dark: '#f89202',
      contrastText: '#ffffff',
    },
  },
  paper: {
    marginTop: 30,
    maxWidth: 936,
    margin: 'auto',
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    header: {
      padding: 20,
    },
    padding: 20,
  },
}

export default {
  ...baseTheme,
  get: (...args) => get(baseTheme, ...args),
}
