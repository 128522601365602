import React from 'react'
import { Block, Text, Button } from 'components/atoms'
import firebase from 'appFirebase/config'
import { Modal } from 'components/molecules'
import IconButton from '@material-ui/core/IconButton'

import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'

// ICONS
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const ChangePasswordModal = ({ open, onClose }) => {
  const auth = firebase.auth()
  const emailAuthProvider = firebase.auth.EmailAuthProvider
  const [values, setValues] = React.useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    errorCode: null,
    loading: false,
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = prop => {
    setValues({ ...values, [prop]: !values[prop] })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const { password, newPassword, confirmPassword } = values
    if (newPassword === confirmPassword) {
      setValues({ ...values, loading: true })
      const cred = emailAuthProvider.credential(
        auth.currentUser.email,
        password
      )

      auth.currentUser
        .reauthenticateWithCredential(cred)
        .then(() => {
          return auth.currentUser.updatePassword(newPassword).then(() => {
            onClose()
            setValues({
              ...values,
              loading: false,
              password: '',
              newPassword: '',
              confirmPassword: '',
            })
          })
        })
        .catch(err => {
          console.log('err', err)
          setValues({
            ...values,
            errorCode: 'Password is incorrect',
          })
        })
    } else {
      setValues({
        ...values,
        errorCode: 'New password does not match',
      })
    }
  }

  return (
    <Modal
      open={open}
      header
      title={'เปลี่ยนรหัสผ่าน'}
      onClose={() => {
        setValues({
          ...values,
          loading: false,
          password: '',
          newPassword: '',
          confirmPassword: '',
        })
        onClose()
      }}
      content={
        <Block width={['auto', 400]}>
          <form onSubmit={handleSubmit}>
            <Block display={'flex'} flexDirection={'column'}>
              <Text variant={'h4'} mb={'8px'}>
                รหัสผ่านเดิม
              </Text>
              <FormControl
                required
                error={values.errorCode === 'Password is incorrect'}
                style={{ marginBottom: 16 }}
              >
                <InputLabel htmlFor="adornment-password">รหัสผ่าน</InputLabel>
                <Input
                  id="adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  inputProps={{ minLength: 6 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => handleClickShowPassword('showPassword')}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {values.errorCode === 'Password is incorrect' && (
                  <Text textAlign={'center'} color={'red'} fontSize={12}>
                    รหัสผ่านไม่ถูกต้อง
                  </Text>
                )}
              </FormControl>
              <Text variant={'h4'} mt={16} mb={'8px'}>
                รหัสผ่านใหม่
              </Text>
              <FormControl
                required
                error={values.errorCode === 'New password does not match'}
                style={{ marginBottom: 8 }}
              >
                <InputLabel htmlFor="adornment-newPassword">
                  รหัสผ่านใหม่
                </InputLabel>
                <Input
                  id="adornment-newPassword"
                  type={values.showNewPassword ? 'text' : 'password'}
                  value={values.newPassword}
                  onChange={handleChange('newPassword')}
                  inputProps={{ minLength: 6 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword('showNewPassword')
                        }
                      >
                        {values.showNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                required
                error={values.errorCode === 'รหัสผ่านใหม่กรอกไม่เหมือนกัน'}
              >
                <InputLabel htmlFor="adornment-confirmPassword">
                  ยืนยันรหัสผ่านใหม่
                </InputLabel>
                <Input
                  id="adornment-confirmPassword"
                  type={values.showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  inputProps={{ minLength: 6 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword('showConfirmPassword')
                        }
                      >
                        {values.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {values.errorCode === 'New password does not match' && (
                  <Text textAlign={'center'} color={'red'} fontSize={12}>
                    ยืนยันรหัสผ่านใหม่ ไม่เหมือนกัน
                  </Text>
                )}
              </FormControl>
              <Button.Contain
                type="submit"
                color={'primary'}
                fullWidth
                mt={30}
                loading={values.loading}
              >
                อัพเดท
              </Button.Contain>
            </Block>
          </form>
        </Block>
      }
    />
  )
}

export default ChangePasswordModal
