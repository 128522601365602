import get from 'lodash/get'

const selectHistoryState = state => get(state, 'history')
export const selectTransactionData = state =>
  selectHistoryState(state).transactions
export const selectGetGetTransactionStatus = state =>
  selectHistoryState(state).getTransactionStatus
export const selectGetGetRedemptionStatus = state =>
  selectHistoryState(state).getRedemptionStatus
export const selectRedemptionData = state =>
  selectHistoryState(state).redemptions
export const selectShouldGetMoreTransaction = state =>
  selectHistoryState(state).shouldGetMoreTransaction
export const selectShouldGetMoreRedemption = state =>
  selectHistoryState(state).shouldGetMoreRedemption
