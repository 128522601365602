import { createMuiTheme } from '@material-ui/core/styles'
import baseTheme from './baseTheme'

const theme = createMuiTheme({
  palette: {
    primary: baseTheme.palette.primary,
  },
  typography: {
    fontFamily: ['Prompt', 'Arial', 'sans-serif'],
    h1: {
      fontSize: 22,
      fontWeight: 'bold',
      letterSpacing: '1.5px',
    },
    h2: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '1.5px',
    },
    h3: {
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      lineHeight: '24px',
    },
    h4: {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: '0.44px',
      lineHeight: '21px',
    },
    h5: {
      fontSize: 32,
    },
    h6: {
      fontSize: 28,
    },
    button: {
      fontSize: 14,
      fontWeight: 'bold',
      color: 'white',
    },
    body1: {
      fontSize: 16,
      letterSpacing: '1px',
    },
    subtitle1: {
      fontSize: 16,
      color: '#ACACAC',
      lineHeight: '24px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 100,
      color: '#363636',
      letterSpacing: '0px',
      lineHeight: '18px',
    },
    overline: {
      fontSize: 16,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        transform: 'skewX(-25deg)',
      },
      label: {
        transform: 'skewX(25deg)',
      },
    },
  },
})

export default theme
