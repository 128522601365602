import React from 'react'

import { Block, Text, Button } from 'components/atoms'
import TextField from '@material-ui/core/TextField'
// import Input from '@material-ui/core/Input'
import NumberFormat from 'react-number-format'
import firebase from 'appFirebase/config'
import FormControl from '@material-ui/core/FormControl'

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange(values.value)
      }}
      format="### - ### - ####"
      placeholder="0XX - XXX - XXXX"
      mask=" "
    />
  )
}

const PhoneInput = ({ updateState, profile }) => {
  const [values, setValues] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [sendNumberLoading, setSendNumberLoading] = React.useState(false)

  const OnSubmit = values => {
    if (values !== profile.phoneNumber) {
      setSendNumberLoading(true)
      const newPhoneNumber = '+66' + values.substring(1)
      firebase.auth().languageCode = 'en'
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'confirm-button',
        {
          size: 'invisible',
          callback: function(response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log(response)
          },
        }
      )
      const appVerifier = window.recaptchaVerifier
      confirmNumber(newPhoneNumber, appVerifier)
    } else {
      setErrorMessage('Please use new phone number')
    }
  }

  const confirmNumber = (newPhoneNumber, appVerifier) => {
    firebase
      .auth()
      .currentUser.linkWithPhoneNumber(newPhoneNumber, appVerifier)
      .then(confirmationResult => {
        // console.log('Sms sent')
        // console.log(confirmationResult)
        setSendNumberLoading(false)
        updateState({
          confirmationResult,
          phoneNumber: newPhoneNumber,
          appVerifier,
        })
      })
      .catch(error => {
        // console.log('error', error)
        if (error.code === 'auth/too-many-requests') {
          setErrorMessage(
            'We have blocked all requests from this device due to unusual activity. Try again later.'
          )
        }
      })
  }

  return (
    <Block textAlign={'center'} position={'relative'}>
      <Text variant={'h2'}>เชื่อมบัญชีกับเบอร์มือถือ</Text>
      <Block my={24}>
        <Text>
          เชื่อมบัญชี Warrix กับเบอร์มือถือ
          <br />
          เพื่อให้คุณไม่พลาดสิทธิพิเศษจากเรา
        </Text>
      </Block>
      <form>
        <FormControl margin="dense" fullWidth component={'div'}>
          <TextField
            label="เบอร์มือถือ"
            value={values}
            onChange={value => {
              setValues(value)
            }}
            id="formatted-number-format-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </FormControl>
        {errorMessage !== null && <Text color="red">*{errorMessage}</Text>}
        <Button.Contain
          type="submit"
          mt={30}
          id="confirm-button"
          fullWidth
          disabled={!values || values.length < 10}
          loading={sendNumberLoading}
          onClick={e => {
            e.preventDefault()
            OnSubmit(values)
          }}
        >
          ยืนยัน
        </Button.Contain>
      </form>
    </Block>
  )
}

export default PhoneInput
