import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ButtonGroup from './ButtonGroup'
import {
  compose,
  typography,
  space,
  flexbox,
  layout,
  grid,
  background,
  border,
  position,
  shadow,
} from 'styled-system'

const CustomButton = styled(
  ({
    classes,
    className,
    flat,
    rounded,
    inverted,
    loading,
    disabled,
    children,
    color,
    active,
    provider,
    ...rest
  }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <Button
            className={cx(
              'button',
              active && 'active',
              flat && 'flat',
              rounded && 'rounded',
              loading && 'loading',
              inverted && 'inverted',
              provider,
              rest.variant === 'outlined' && 'outlined',
              rest.variant === 'contained' && 'contained',
              className
            )}
            {...rest}
            {...(loading || disabled ? { disabled: true } : { color })}
          >
            {children}
            {loading && (
              <CircularProgress
                className={'circular-progress'}
                size={20}
                color={'inherit'}
              />
            )}
          </Button>
        )}
      </ClassNames>
    )
  }
)(
  {
    '&.button svg': {
      paddingRight: 5,
    },
    '&.rounded': {
      borderRadius: 30,
    },
    '&.loading': {
      position: 'relative',
    },
    '&.loading span': {
      visibility: 'hidden',
    },
    '&.contractForm': {
      width: 200,
    },
    '&.loading .circular-progress': {
      visibility: 'visible',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: '-10px',
      marginTop: '-10px',
    },
  },
  compose(
    typography,
    space,
    flexbox,
    layout,
    grid,
    background,
    border,
    position,
    shadow
  ),
  props => ({
    '&.button': space(props),
  }),
  ({ theme }) => ({
    '&.button': {
      boxShadow: 'none',
    },
    '&.line': {
      background: theme.colors.line,
      ':hover': {
        background: theme.colors.line,
      },
    },
    '&.facebook': {
      background: theme.colors.facebook,
      ':hover': {
        background: theme.colors.facebook,
      },
    },
  })
)

CustomButton.presets = {
  contained: {
    variant: 'contained',
    flat: true,
    color: 'primary',
  },
  outlined: {
    variant: 'outlined',
    color: 'primary',
  },
}
CustomButton.Group = ButtonGroup
CustomButton.Contain = props => (
  <CustomButton {...CustomButton.presets.contained} {...props} />
)
CustomButton.OutLined = props => (
  <CustomButton {...CustomButton.presets.outlined} {...props} />
)
CustomButton.Delete = props => (
  <CustomButton
    {...props}
    {...CustomButton.presets.contained}
    color={'secondary'}
  />
)
CustomButton.propTypes = {
  loading: PropTypes.bool,
  flat: PropTypes.bool,
}
CustomButton.defaultProps = {
  loading: false,
  flat: true,
}

export default CustomButton
