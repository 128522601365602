import React from 'react'
import { Block, Button, Text } from 'components/atoms'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import * as Yup from 'yup'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

// REDUX
import { creatAddress, editAddressData } from 'modules/auth/actions'
import {
  selectCreateAddressStatus,
  selectEditAddressStatus,
} from 'modules/auth/selectors'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const AddressForm = ({
  geo,
  creatAddress,
  createAddressStatus,
  closeForm,
  editAddress = null,
  editAddressStatus,
  editAddressData,
  defaultAddress = false,
  hasCancelButton,
  inRedeemRewardModal,
  canEditDefaultAddress,
}) => {
  const filterOptions = (key, values, options) => {
    const { districts, provinces } = geo
    if (key === 'district') {
      return options.filter(
        option =>
          option.value.slice(0, 2) ===
          get(provinces.find(province => province.label === values), 'value')
      )
    }
    if (key === 'subDistrict') {
      return options.filter(
        option =>
          option.value.slice(0, 4) ===
          get(districts.find(district => district.label === values), 'value')
      )
    }
  }

  const autoFilledPostCode = values => {
    const postcode = geo.postcodes.find(
      postcode =>
        postcode.district === get(values, 'district') &&
        postcode.province === get(values, 'province')
    )
    return postcode.postCode
  }

  const [defaultAddressField, setDefaultAddressField] = React.useState(
    get(editAddress, 'defaultAddress', false) || defaultAddress
  )

  return (
    <Block
      css={{
        '.MuiFormHelperText-root': {
          color: '#f2483f',
        },
        '.MuiFormControl-root': {
          width: '100%',
        },
        '.MuiInputLabel-formControl': {
          background: 'white',
        },
      }}
    >
      {inRedeemRewardModal && <Text variant={'h2'}>เพิ่มที่อยู่จัดส่ง</Text>}
      <Formik
        initialValues={
          editAddress || {
            receiver: '',
            address: '',
            province: '',
            district: '',
            subDistrict: '',
            postcode: '',
            phone: '',
          }
        }
        validationSchema={Yup.object().shape({
          receiver: Yup.string().required('กรุณากรอก ชื่อผู้รับ'),
          address: Yup.string().required('กรุณากรอก ที่อยู่'),
          province: Yup.string().required('กรุณาเลือก จังหวัด'),
          district: Yup.string().required('กรุณาเลือก เขต/อำเภอ'),
          subDistrict: Yup.string().required('กรุณาเลือก แขวง/ตำบล'),
          postcode: Yup.string().required('กรุณากรอก รหัสไปรษณีย์'),
          phone: Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              'กรุณากรอกเบอร์ให้ถูกต้อง'
            )
            .required('กรุณากรอก เบอร์โทรศัพท์'),
        })}
        onSubmit={values => {
          if (editAddress) {
            return editAddressData({
              ...values,
              defaultAddress: defaultAddressField,
            }).then(() => {
              closeForm()
            })
          }
          return creatAddress({
            ...values,
            defaultAddress: defaultAddressField,
          }).then(() => {
            closeForm()
          })
        }}
        render={({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              required
              label="ชื่อผู้รับ"
              name="receiver"
              value={values.receiver}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.receiver && touched.receiver && errors.receiver
              }
              margin="dense"
            />
            <TextField
              required
              label="ที่อยู่"
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.address && touched.address && errors.address}
              margin="dense"
            />
            <br />
            <Block display={'flex'} justifyContent={'space-between'}>
              <Block width={'45%'}>
                <FormControl required>
                  <InputLabel>จังหวัด</InputLabel>
                  <Select
                    required
                    onBlur={handleBlur}
                    id="demo-simple-select-outlined"
                    name="province"
                    label="จังหวัด"
                    onChange={handleChange}
                    value={values.province}
                  >
                    {geo &&
                      sortBy(geo.provinces, ['label']).map(province => (
                        <MenuItem value={province.label}>
                          {province.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.province && touched.province && errors.province && (
                    <FormHelperText>{errors.province}</FormHelperText>
                  )}
                </FormControl>
              </Block>
              <Block width={'45%'}>
                <FormControl disabled={!get(values, 'province')} required>
                  <InputLabel>เขต/อำเภอ</InputLabel>
                  <Select
                    required
                    onBlur={handleBlur}
                    id="demo-simple-select-outlined"
                    name="district"
                    label="เขต/อำเภอ"
                    onChange={handleChange}
                    value={values.district}
                  >
                    {geo &&
                      filterOptions(
                        'district',
                        values.province,
                        sortBy(geo.districts, ['label'])
                      ).map(district => (
                        <MenuItem value={district.label}>
                          {district.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.district && touched.district && errors.district && (
                    <FormHelperText>{errors.district}</FormHelperText>
                  )}
                </FormControl>
              </Block>
            </Block>
            <Block
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Block width={'45%'}>
                <FormControl
                  disabled={
                    !get(values, 'province') || !get(values, 'district')
                  }
                  required
                >
                  <InputLabel>แขวง/ตำบล</InputLabel>
                  <Select
                    required
                    onBlur={handleBlur}
                    id="demo-simple-select-outlined"
                    name="subDistrict"
                    label="แขวง/ตำบล"
                    onChange={event => {
                      handleChange(event)
                      setFieldValue('postcode', autoFilledPostCode(values))
                    }}
                    value={values.subDistrict}
                  >
                    {geo &&
                      filterOptions(
                        'subDistrict',
                        values.district,
                        sortBy(geo.subDistricts, ['label'])
                      ).map(subDistrict => (
                        <MenuItem value={subDistrict.label}>
                          {subDistrict.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.subDistrict &&
                    touched.subDistrict &&
                    errors.subDistrict && (
                      <FormHelperText>{errors.subDistrict}</FormHelperText>
                    )}
                </FormControl>
              </Block>
              <Block width={'45%'}>
                <TextField
                  required
                  label="รหัสไปรษณีย์"
                  name="postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.postCode && touched.postCode && errors.postCode
                  }
                  margin="dense"
                />
              </Block>
            </Block>
            <TextField
              required
              label="เบอร์โทรติดต่อ"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.phone && touched.phone && errors.phone}
              margin="dense"
              placeholder={'0xxxxxxxxx'}
            />
            {editAddress && canEditDefaultAddress && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={defaultAddressField}
                    onChange={event =>
                      setDefaultAddressField(event.target.checked)
                    }
                  />
                }
                label="ตั้งเป็นที่อยู่หลัก"
              />
            )}
            <Button.Contain
              type="submit"
              mt={20}
              fullWidth
              loading={
                createAddressStatus.isPending || editAddressStatus.isPending
              }
            >
              {!editAddress ? 'เพิ่มที่อยู่' : 'แก้ไขที่อยู่'}
            </Button.Contain>

            {hasCancelButton && (
              <Button.OutLined mt={20} fullWidth onClick={() => closeForm()}>
                ยกเลิก
              </Button.OutLined>
            )}
          </form>
        )}
      />
    </Block>
  )
}

const mapStateToProps = state => ({
  createAddressStatus: selectCreateAddressStatus(state),
  editAddressStatus: selectEditAddressStatus(state),
})

export default connect(
  mapStateToProps,
  dispatch =>
    bindActionCreators(
      {
        creatAddress,
        editAddressData,
      },
      dispatch
    )
)(AddressForm)
