import get from 'lodash/get'

const selectAuthState = state => get(state, 'auth')
export const selectAuthData = state => selectAuthState(state).data
export const selectGetAuthStatus = state =>
  selectAuthState(state).getProfileStatus
export const selectObserveAuthStatus = state =>
  selectAuthState(state).observeAuth
export const selectRegisterFacebookStatus = state =>
  selectAuthState(state).registerFacebook

export const selectAddressData = state => selectAuthState(state).addresses
export const selectGetAddressStatus = state =>
  selectAuthState(state).getAddressStatus

export const selectCreateAddressStatus = state =>
  selectAuthState(state).createAddressStatus
export const selectDeleteAddressStatus = state =>
  selectAuthState(state).deleteAddressStatus
export const selectEditAddressStatus = state =>
  selectAuthState(state).editAddressStatus
