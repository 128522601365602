import React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import 'moment/locale/th'
import { Block, Text } from 'components/atoms'

moment.locale('th')

const HistoryList = ({ history, redemption }) => {
  const renderTransactionType =(history)=>{
    if(get(history,'event')){
      return `จากการเข้าร่วมงาน ${history.event.name}`
    }
    return `จากโค้ด ${history.code} ในรายการ ${history.campaign.name}`
  }
  return (
    <Block
      display={'flex'}
      width={'100%'}
      py={16}
      borderBottom={'1px solid #f7f9f9'}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <Block display={'flex'} alignItems={'center'} width={['50%', '30%']}>
        <Block
          width={12}
          height={12}
          background={!redemption ? '#6fd422' : '#f15a29'}
          mr={10}
          borderRadius={'50%'}
        />
        <Text variant={'h4'}>
          {!redemption ? `ได้รับ ${history.point} แต้ม` : 'แลกแต้ม'}
        </Text>
      </Block>
      <Block width={['50%', '25%']} textAlign={['right', 'left']}>
        <Text variant={'caption'} fontSize={['10px', '12px']}>
          {moment(history.createdAt).format('DD MMM YYYY HH:mm')}
        </Text>
      </Block>
      <Block width={['100%', '45%']} mt={['4px', 0]}>
        <Text variant={'caption'}>
          {!redemption
            ? renderTransactionType(history)
            : `ใช้ ${history.point} แต้ม แลก ${history.reward.name}`}
        </Text>
      </Block>
    </Block>
  )
}

export default HistoryList
