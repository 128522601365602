import React from 'react'
import { Modal } from 'components/molecules'
import { Block } from 'components/atoms'
import AddressForm from 'components/forms/AddressForm'

const AddressModal = ({
  open,
  onClose,
  geo,
  editAddress,
  defaultAddress,
  canEditDefaultAddress,
}) => {
  return (
    <Modal
      open={open}
      header
      title={editAddress ? 'แก้ไขที่อยู่' : 'เพิ่มที่อยู่ใหม่'}
      onClose={() => onClose()}
      content={
        <Block width={['auto', 400]}>
          <AddressForm
            geo={geo}
            closeForm={onClose}
            editAddress={editAddress}
            defaultAddress={defaultAddress}
            canEditDefaultAddress={canEditDefaultAddress}
          />
        </Block>
      }
    />
  )
}

export default AddressModal
